import React, { useEffect } from "react"
import { motion } from "framer-motion"
import logoPlavi from "../assets/LogoOsijekKoteks1-1024x217_4_270x60.png"
import { Link } from "gatsby"
import { FaBars } from "react-icons/fa"
import PageLinks from "../constants/links"
import { useLanguage } from "../contexts/LanguageContext"

const hoverMotion = {
  rest: {},
  hover: {},
}

const hoverMotionChild = {
  rest: {
    rotate: 0,
    transition: {
      duration: 0.4,
    },
  },
  hover: {
    rotate: 270,
    transition: {
      duration: 0.4,
    },
  },
}

function LanguageSelect({ selectLanguage, selectedLanguage }) {
  return (
    <div className="language-select">
      <p
        className={`language-text language-blue ${
          selectedLanguage === "HR" ? "selected" : ""
        }`}
        onClick={() => selectLanguage("HR")}
      >
        HR
      </p>
      <p className="language-text language-blue">|</p>
      <p
        className={`language-text language-blue ${
          selectedLanguage === "EN" ? "selected" : ""
        }`}
        onClick={() => selectLanguage("EN")}
      >
        EN
      </p>
    </div>
  )
}

const Navbar = ({ toggleSidebar }) => {
  const { selectedLanguage, selectLanguage } = useLanguage()
  useEffect(() => {
    const storedLanguage = localStorage.getItem("selectedLanguage")
    if (storedLanguage) {
      selectLanguage(storedLanguage)
    }
  }, [selectLanguage])

  return (
    <nav className="navbar">
      <div className="nav-center">
        <div className="nav-header">
          <Link to="/">
            <img src={logoPlavi} alt="logo" className="logo-img" />
          </Link>
          <LanguageSelect
            selectLanguage={selectLanguage}
            selectedLanguage={selectedLanguage}
          />
          <motion.button
            variants={hoverMotion}
            initial="rest"
            whileHover="hover"
            animate="rest"
            type="button"
            onClick={toggleSidebar}
            className="toggle-btn"
          >
            {selectedLanguage === "EN" ? (
              <div className="menu-text-two language-blue">Menu</div>
            ) : (
              <div className="menu-text-two language-blue">Izbornik</div>
            )}

            <motion.span
              variants={hoverMotionChild}
              className="menu-icon-two language-blue"
            >
              <FaBars />
            </motion.span>
          </motion.button>
        </div>

        <div>
          <PageLinks styleClass="nav-links"></PageLinks>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
